<template>
  <div class="wrapper-card">
    <div class="badge-information p-2 price">
      <span class="icon"><img src="/assets/img/after-booking-icon1.png" alt="after-booking-icon" title="" /></span>
      {{ $t("booking.total-to-pay") }} : <strong>₪{{ price }}</strong>
    </div>
    <div style="min-height:180px; text-align:center;">
      <iframe id="verifyingCard" title="Card Verifying" :src="path1" v-show="loaded"></iframe>
      <img src="/assets/img/iframe-swimming-loading.gif" class="loading-gif" v-if="!loaded" alt="iframe-swimming-loading"/>
    </div>

    <b-modal id="failCardVerifyModal" centered size="lg" dir="rtl" :no-close-on-backdrop="true" :ok-only="true">
      <template #modal-header="{}">
        <div class="d-flex flex-grow-1 align-items-baseline text-right" >
          <h3 class="m-auto">{{ $t("booking.notice") }}</h3>
          <h5>{{ maxSec }} {{ $t("booking.sec") }}</h5>
        </div>
      </template>
      <template>
        <p class='text-right'>{{ $t("redirect.no-fill-card") }}</p>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="gotoBookingPage(); ok();">
          {{ $t("booking.ok") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { fromBase64 } from '@/utils/base64Util';

export default {
  data() {
    return {
      loaded: false,
      product: null,
      price: 0,
      path1: '',
      maxSec: 3 * 60,
      timeIntervalID: null,
      timeoutID: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      path: 'GET_VERIFYING_URL',
      bookingDataID: 'GET_BOOKING_DATA_ID',
      marketerId: 'GET_MARKETER_ID',
      subMarketerId: 'GET_SUB_AGENCY',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      agencyOwnerName: 'GET_AGENCY_OWNER_NAME',
      affiliation: 'GET_AFFILIATION',
      isDirectPayment: 'GET_DIRECT_PAYMENT_STATE',
    }),
  },
  created() {
    this.path1 = this.path || window.localStorage.getItem('VERIFYING_URL');
    window.localStorage.setItem('VERIFYING_URL', this.path1);
    if (document.querySelector('.st-content'))document.querySelector('.st-content').scrollTo(0, 0);

    this.product = JSON.parse(fromBase64(window.localStorage.getItem('BookingDetailInfo'))).data;
    this.price = this.product.grandTotalAfterDiscount;
    const { marketerId, subMarketerId } = this;
    window.localStorage.setItem('marketer', JSON.stringify({ marketerId, subMarketerId }));
  },
  unmounted() {
    window.removeEventListener('popstate');
    window.clearInterval(this.timeIntervalID);
    window.clearTimeout(this.timeoutID);
  },
  mounted() {
    const that = this;
    document.getElementById('verifyingCard').onload = () => {
      that.loaded = true;
      that.afterIframeLoaded();
    };

    if (!this.isOdysseySite) {
      const bookingDataID = this.bookingDataID || window.localStorage.getItem('BookingDataID'),
        { hotel: { hotelName, cityName, checkinDate, checkoutDate }, priceBeforeCoupon } = this.product;
      const storedAgencyOwnerName = JSON.parse(fromBase64(window.sessionStorage.getItem('agencyOwner') || ''));
      const name = `${hotelName} - ${cityName} - ${storedAgencyOwnerName || this.agencyOwnerName}`;

      this.$store.dispatch('SENDING_GA_EVENT', {
        eventName: 'begin_checkout',
        currency: 'ILS',
        value: priceBeforeCoupon,
        items: [
          {
            item_id: bookingDataID,
            item_name: name,
            affiliation: this.affiliation,
            item_category: cityName,
            item_category2: `${checkinDate} - ${checkoutDate}`,
            price: priceBeforeCoupon,
          },
        ],
      });
      // window.gtag('event', 'begin_checkout', {
      //   currency: 'ILS',
      //   value: priceBeforeCoupon,
      //   items: [
      //     {
      //       item_id: bookingDataID,
      //       item_name: name,
      //       affiliation: this.affiliation,
      //       coupon: '',
      //       discount: '',
      //       index: 0,
      //       item_brand: '',
      //       item_category: cityName,
      //       item_category2: `${checkinDate} - ${checkoutDate}`,
      //       item_category3: '',
      //       item_category4: '',
      //       item_category5: '',
      //       item_list_id: '',
      //       item_list_name: '',
      //       item_variant: '',
      //       location_id: '',
      //       price: priceBeforeCoupon,
      //       quantity: 1,
      //     },
      //   ],
      // });

      this.$store.dispatch('JOURNEY_OPEN_CARD_PAGE');
    }

    window.addEventListener('popstate', () => {
      const isDirectPaymentState = window.localStorage.getItem('direct-payment') === 'true';
      if (isDirectPaymentState) this.$store.dispatch('GET_BOOKED_INFO', { isDirect: isDirectPaymentState });
    });
  },
  methods: {
    afterIframeLoaded() {
      // const { host } = window.location;
      // if (host.includes('localhost') || host.includes('vercel')) {
      //   this.timeoutID = setTimeout(() => {
      //     window.location.href = '/fail-card-verified';
      //   }, 1000 * 60 * 5);
      // }

      this.timeoutID = setTimeout(() => {
        this.showTimeFailedModal();
      }, 5 * 60 * 1000);

      // const { payer } = this.product;
      // const { identificationNumber, creditCardExpirMonth, creditCardExpirYear, noOfPayment } = payer;
      // const iframe = document.getElementById('verifyingCard');
      // const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
      // innerDoc.getElementById('paymentByCC').innerHtml = 'תשלום באמצעות כרטיס אשראי';
      // innerDoc.getElementById('credit_card_number_input').values = '375510290006779';
      // innerDoc.getElementById('date_year_input').value = creditCardExpirMonth;
      // innerDoc.getElementById('date_month_input').value = creditCardExpirYear;
      // innerDoc.getElementById('id_number_input').value = identificationNumber;
      // innerDoc.getElementById('cvv_input').value = noOfPayment;
    },
    showTimeFailedModal() {
      window.localStorage.setItem('retryState', JSON.stringify({ readOnly: true }));
      this.$bvModal.show('failCardVerifyModal');
      this.timeIntervalID = setInterval(() => {
        this.maxSec -= 1;
        if (this.maxSec === 0) {
          this.gotoBookingPage();
        }
      }, 1000);
    },
    gotoBookingPage() {
      this.$bvModal.hide('failCardVerifyModal');
      if (this.isDirectPayment) {
        this.$router.push({ path: '/personal-area' });
      } else {
        window.parent.location.href = `/booking/hotel?lang=${this.lang}`;
      }
    },
  },
};
</script>

<style scoped>

  #verifyingCard{
    width: 100%;
    height: 670px;
    border: none;
  }

  .wrapper-card{
    margin : auto;
    width: 100%;
    height: 100%;
  }

  .price{
    font-size : 30px;
  }
  .loading-gif {
    max-width: 100px;
    text-align: center;
    margin-top: 50px;
  }
</style>
