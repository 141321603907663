<template>
  <modal v-bind="properties"
    :class="minimizeState && 'minimize'"
    @opened="opened"
    @closed="closed">
    <div class="modal-header">
      <span v-if="!minimizeState">
        {{ $t('caller-info-modal.modal-title', {callerPhoneNumber : callerInfo?.callerPhone || ''}) }}
        <b-badge variant="success" v-if="isPurchaser">purchaser</b-badge>
        <b-badge variant="danger" v-else>no purchaser</b-badge>
      </span>

      <span>
        <button type="button" class="close p-0"
          @click="minimizeState = !minimizeState"
          @touchend="minimizeState = !minimizeState" aria-label="Close">
          <span aria-hidden="true">&ndash;</span>
        </button>
        <button type="button" class="close p-0 mr-3" v-if="!minimizeState"
          @click="$modal.hide('caller-info')"
          @touchend="$modal.hide('caller-info')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </span>

    </div>
    <div class="modal-body" v-if="!minimizeState">
      <b-card no-body>
        <b-tabs small card>
          <b-tab :title="$t('caller-info-modal.general-tab-title')">

            <b-form-group
              label-cols="3" :id="key" :label="$t(`caller-info-modal.${key}`)"
              v-for="(key, inx) in Object.keys(generalInfo)" :key="inx">

              <b-form-input readonly :value="formatDate(generalInfo[key])" v-if="key.includes('Time')"></b-form-input>
              <div v-else-if="generalInfo[key] && typeof generalInfo[key] === 'object'">
                <b-form-group
                  label-cols="3" :label="$t(`caller-info-modal.${key}-${sKey}`)"
                  v-for="(sKey, sInx) in Object.keys(generalInfo[key])" :key="sInx">
                    <b-form-input readonly :value="generalInfo[key][sKey]"></b-form-input>
                </b-form-group>
              </div>
              <b-form-input readonly :value="generalInfo[key]" v-else></b-form-input>

            </b-form-group>
            <b-form-input readonly v-model="editableMessage"></b-form-input>

            <div class="mt-3 text-left">
              <b-button variant="primary" :disabled="sendButtonDisable" @click="sendWhatsApp">{{$t('caller-info-modal.send-whatsapp-message')}}</b-button>
            </div>

          </b-tab>
          <b-tab :title="$t('caller-info-modal.browsing-history')">
            <b-card-group columns v-if="browsingHistory.length">

              <b-card v-for="(his, inx) in browsingHistory" :key="inx" header="Primary">
                <template #header>
                  <span>{{ his.browsingAction }}</span>
                </template>
                <b-card-sub-title>Caller Phone: {{ his.callerPhone }}</b-card-sub-title>
                <b-card-text>
                  <div v-if="his.data.agent"> Agent:{{ his.data.agent }}</div>
                  <div v-if="his.data.bookingTransId"> Booking Id:{{ his.data.bookingTransId }}</div>
                  <div v-if="his.data.grandTotal"> Grand Total: {{ his.data.grandTotal }}</div>
                  <div v-if="his.data.currency"> Currency: {{ his.data.currency }}</div>
                  <div v-if="his.data.pnr"> PNR: {{ his.data.pnr }}</div>
                  <div v-if="typeof his.data.debited === 'boolean'"> Debited: {{ his.data.debited }}</div>
                  <div v-if="typeof his.data.hotelStatus === 'object'"> Hotel Status: {{ his.data.hotelStatus }}</div>
                  <div>
                    <span v-if="typeof his.data.adults === 'number'"> {{ `Adults: ${his.data.adults}` }}</span>
                    <span v-if="typeof his.data.children === 'number'"> {{ `Children: ${his.data.children}` }}</span>
                    <span v-if="typeof his.data.infants === 'number'"> {{ `Infants: ${his.data.infants}` }}</span>
                  </div>
                  <div v-if="his.data.destCity"> Dest: {{ his.data.destCity.map((city) => (`${city.code}-${city.name}`)).join(',') }}</div>

                  <div v-if="his.data.hotel && Array.isArray(his.data.hotel)">
                    Hotel: {{ his.data.hotel.map((hotel) => (`${hotel.id}-${hotel.name}`)).join(',') }}
                  </div>

                  <div v-if="his.data.hotel && typeof his.data.hotel === 'object' && !Array.isArray(his.data.hotel)" class="border p-1">
                    <div v-for="(hKey, hInx) in Object.keys(his.data.hotel)" :key="hInx">{{ `${hKey}: ${his.data.hotel[hKey]}` }}</div>
                  </div>

                  <div v-if="his.data.reservationPeriod">
                    Period: {{ his.data.reservationPeriod.map((period) => (`${period.checkin}, ${period.checkout}`)).join(',') }}
                  </div>

                </b-card-text>
                <template #footer>
                  <small class="text-muted">Event Time: {{ formatDate(his.eventTime) }}</small>
                </template>
              </b-card>

            </b-card-group>

          </b-tab>
          <b-tab :title="$t('caller-info-modal.call-history')">
            <b-card-group columns  v-if="callHistory.length">
              <b-card v-for="(his, inx) in callHistory" :key="inx">
                <template #header>
                  <span>{{ his.callCenterSystem }}</span>&nbsp;
                  <b-badge variant="success" v-if="his.isAnswered">ans</b-badge>
                  <b-badge variant="dark" v-else>no-ans</b-badge>
                </template>
                <b-card-sub-title>
                  Caller Phone: {{ his.callerPhone }}
                </b-card-sub-title>
                <div v-for="(subKey, inx) in Object.keys(callHistoryOmit(his))" :key="inx">
                  {{ subKey }}: {{ his[subKey] }}
                </div>
                <div v-if="his.assignedAgentInfo" class="border p-1">
                  <div v-for="(aKey, aInx) in Object.keys(his.assignedAgentInfo)" :key="aInx">{{ `${aKey}: ${his.assignedAgentInfo[aKey]}` }}</div>
                </div>
                <template #footer>
                  <small class="text-muted">Arrived Time: {{ formatDate(his.arrivedTime) }}</small>
                </template>
              </b-card>

            </b-card-group>

          </b-tab>
        </b-tabs>
      </b-card>
    </div>

  </modal>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { BCardGroup, BCard, BCardSubTitle, BCardText, BTabs, BTab, BFormGroup, BFormInput, BBadge } from 'bootstrap-vue';

export default {
  name: 'caller-info-modal',
  components: {
    BCardGroup, BCard, BCardText, BCardSubTitle, BTabs, BTab, BFormGroup, BFormInput, BBadge,
  },
  computed: {
    ...mapGetters({
      callerInfo: 'GET_CALLER_INFO',
      whatsAppMessageRes: 'GET_SENT_WHATSAPP_MESSAGE_RES',
      isStaging: 'GET_IS_STAGING_STATE',
      // socketErrorState: 'GET_SOCKET_ERROR_STATE',

      isAgencyAgentLogin: 'IS_AGENCY_AUTHORIZED',
      isSocketConnected: 'IS_SOCKET_CONNECTED',
    }),
    generalInfo() {
      const { callerInfo } = this;
      if (!callerInfo) return {};
      const { browsingHistory, callHistory, purchaser, assignedAgentInfo, callStatus, assignedAgent, firstlyAnsweredAgentInfo, firstlyAnsweredAgent, ...rest } = callerInfo; // eslint-disable-line no-unused-vars
      if (firstlyAnsweredAgentInfo.name) {
        rest.firstlyAnsweredAgentInfo = firstlyAnsweredAgentInfo;
      } else {
        rest.firstlyAnsweredAgent = firstlyAnsweredAgent;
      }
      return rest;
    },
    isPurchaser() {
      const { callerInfo } = this;
      return callerInfo?.purchaser ?? false;
    },
    browsingHistory() {
      const { callerInfo } = this;
      if (!callerInfo) return [];
      return callerInfo.browsingHistory;
    },
    callHistory() {
      const { callerInfo } = this;
      if (!callerInfo) return [];
      return callerInfo.callHistory;
    },
    localPhone() {
      const { callerInfo } = this;
      return callerInfo?.assignedAgentInfo?.isrPhone || '';
    },
    assignedAgentName() {
      const { callerInfo } = this;
      return callerInfo?.assignedAgentInfo?.name || callerInfo?.assignedAgent || '';
    },
    editableMessage() {
      const { assignedAgentName, localPhone } = this;
      return this.$t('caller-info-modal.message', { assignedAgentName, localPhone });
    },
  },
  watch: {
    callerInfo() {
      if (!this.isStaging) return;
      this.minimizeState = false;
      this.$modal.show('caller-info');
    },
    whatsAppMessageRes() {
      this.$store.commit('SET_ERROR_MESSAGE_INFO', {
        title: 'WhatsApp message sent result',
        message: this.whatsAppMessageRes,
      });
    },
    // socketErrorState() {
    //   this.$store.dispatch('NOTIFY_ADMIN', {
    //     subject: `Domestic - ${this.socketErrorState.title}`,
    //     body: `<h4>${this.socketErrorState.title}</h4> <p>${this.socketErrorState.message}</p>`,
    //   });
    // },
  },
  data() {
    return {
      minimizeState: false,
      properties: {
        name: 'caller-info',
        classes: ['caller-info'],
        height: 'auto',
        draggable: '.modal-header',
        adaptive: true,
        width: '50%',
        clickToClose: false,
        minWidth: 350,
        resizable: true,
      },
      sendButtonDisable: false,
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY hh:mm');
    },
    callHistoryOmit(obj) {
      const { callCenterSystem, arrivedTime, callerPhone, isAnswered, assignedAgentInfo, ...rest } = obj; // eslint-disable-line no-unused-vars
      return rest;
    },
    async sendWhatsApp() {
      const callerPhoneNumber = this.callerInfo?.callerPhone || null;

      if (!this.callerInfo) {
        this.$store.commit('SET_ERROR_MESSAGE_INFO', {
          title: 'Caller info error',
          message: 'This session has no any caller info',
        });
        return;
      }
      if (!callerPhoneNumber || (`${callerPhoneNumber}`).trim() === '') {
        this.$store.commit('SET_ERROR_MESSAGE_INFO', {
          title: 'Phone number error',
          message: 'The phone number is mandatory',
        });
        return;
      }
      this.sendButtonDisable = true;
      await this.$store.dispatch('JOURNEY_SEND_WHATSAPP_MESSAGE', {
        callerPhone: callerPhoneNumber,
        message: this.editableMessage,
      });
      this.sendButtonDisable = false;
    },
    opened() {
      this.minimizeState = false;
    },
    closed() {
      this.minimizeState = false;
    },
  },
};
</script>

<style>
.bottom-right-phone-icon .vm--container.minimize,
.bottom-right-phone-icon .vm--container.minimize .vm--overlay {
  width: auto;
  height: auto;
}
.minimize .caller-info.vm--modal {
  width: auto !important;
  top: 63px !important;
  left: 0 !important;
  border-radius: 0;
  box-shadow: none;
  transition: all 0.5s;
}
.minimize .caller-info .modal-header {
  border-radius: 0;
  padding: 0.5rem 2rem;
  border: none;
}
.caller-info.vm--modal {
  position: absolute;
  box-shadow: 1px 1px 20px black;
}
.caller-info .modal-header .close {
  color: #fff;
  text-shadow: none;
  font-size: 2rem;
  line-height: 1rem;
  margin: 0;
}
.caller-info .modal-header {
  cursor: pointer;
  color: #fff;
  background-color: rgba(var(--theme-primary));
  margin: 0;
}
.caller-info .modal-body {
  margin: 0;
}
.caller-info .tab-pane.card-body {
  max-height: 550px;
  overflow-y: auto;
}
.caller-info .tab-pane.card-body .border{
  border: 1px gray solid;
  border-radius: 5px;
}
</style>
