import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import { ModalPlugin, ToastPlugin, TooltipPlugin, BButton } from 'bootstrap-vue';
import VModal from 'vue-js-modal';
import VueNativeSock from 'vue-native-websocket';
import VueLogger from 'vuejs-logger';
import VueMeta from 'vue-meta';
import router from './router';
import store from './store/index';
import i18n from './i18n';
import App from './App';

const isProduction = process.env.NODE_ENV === 'production';
let { host } = window.location;
const { VUE_APP_SOCKET_ENDPOINT, VUE_APP_SOCKET_ENDPOINT_STAGING, VUE_APP_SOCKET_ENDPOINT_AZURE } = process.env;
const socketHost = (host.includes('localhost') || host.includes('vercel')) ? VUE_APP_SOCKET_ENDPOINT_STAGING : ( (host.includes('new')) ? VUE_APP_SOCKET_ENDPOINT_AZURE : VUE_APP_SOCKET_ENDPOINT);

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};
Vue.use(ModalPlugin);
Vue.use(ToastPlugin);
Vue.use(VModal);
Vue.use(VueLogger, options);
Vue.use(VueMeta);
Vue.use(TooltipPlugin);
Vue.use(VueNativeSock, `${socketHost}`, {
  store,
  connectManually: true,
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 3000,
});
Vue.component('b-button', BButton);
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
