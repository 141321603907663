import { mapGetters } from 'vuex';
import agencyMarketer from '@/mixins/agencyMarketer';

export default {
  name: 'TemplateTopNav',
  props: {
    msg: String,
  },
  mixins: [agencyMarketer],
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      mediaSize: 'GET_MEDIA_SIZE',
      lang: 'GET_LANGUAGE',
      subMarketerId: 'GET_SUB_AGENCY',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      currentPage: 'GET_CURRENT_PAGE',
      odyAgentCode: 'GET_LOGIN_ODYSSEY_AGENT_CODE',
      externalBookingInfo: 'GET_EXTERNAL_BOOKING_INFO',
      agentContent: 'GET_ODYSSEY_AGENT_CONTENT',
      isEmalonMode: 'GET_IS_EMALON_MODE',
      isAgentSite: 'GET_ODYSSEY_AGENT_STATE',
      isStaging: 'GET_IS_STAGING_STATE',
      isAzure: 'GET_IS_AZURE_STATE',
    }),
    srcLogo() {
      const { marketerAgencyContent } = this;
      const logoUrls = (this.marketerAgencyContent === null || this.marketerAgencyContent === undefined) ? this.whiteLabel?.logoUrls : marketerAgencyContent?.logoUrls;

      // check domain contains 'emalon' phrase
      const isEmalonAgent = window.location.href.includes('emalon');
      return this.isAgentSite && isEmalonAgent ? logoUrls?.[1] : logoUrls?.[0];
    },
    logoURL() {
      const { marketerId, subMarketerId } = this;
      let url = '/';
      if (marketerId && marketerId !== '') url = `${url}?marketerId=${marketerId}`;
      if (subMarketerId && subMarketerId !== '') url = `${url}&subMarketerId=${subMarketerId}`;
      return url;
    },
    homeLink() {
      const { marketerId, subMarketerId } = this;
      let url = '/';
      if (this.marketerAgencyContent?.marketerSpecificContents?.homeUrl && this.marketerAgencyContent?.marketerSpecificContents?.homeUrl !== '') {
        if (this.marketerAgencyContent?.marketerSpecificContents?.homeUrl.includes('https://')) {
          url = this.marketerAgencyContent?.marketerSpecificContents?.homeUrl;
        } else {
          url = `https://${this.marketerAgencyContent?.marketerSpecificContents?.homeUrl}`;
        }
      }
      if (marketerId && marketerId !== '') url = `${url}?marketerId=${marketerId}`;
      if (subMarketerId && subMarketerId !== '') url = `${url}&subMarketerId=${subMarketerId}`;
      return url;
    },
    isRegularSite() {
      return this.marketerId === '' && this.marketerAgencyContent === null;
    },
    fellowLink() {
      let url = '';
      if (this.marketerId !== '') {
        if (this.marketerAgencyContent?.marketerSpecificContents?.fellowSiteUrl) {
          if (this.marketerAgencyContent?.marketerSpecificContents?.fellowSiteUrl.includes('https://')) {
            url = this.marketerAgencyContent?.marketerSpecificContents?.fellowSiteUrl;
          } else {
            url = `https://${this.marketerAgencyContent?.marketerSpecificContents?.fellowSiteUrl}`;
          }
        }
      } else {
        const {VUE_APP_PACK_DOMAIN, VUE_APP_PACK_DOMAIN_STAGING, VUE_APP_PACK_DOMAIN_AZURE} = process.env;
        url = this.isStaging ? VUE_APP_PACK_DOMAIN_STAGING : (this.isAzure ? VUE_APP_PACK_DOMAIN_AZURE : VUE_APP_PACK_DOMAIN);
      }
      return url;
    },
    showFellowLink() {
      let showPack = true;
      const marketerSite = this.marketerId !== '' || !this.whiteLabel?.forMainSite;
      if (marketerSite) {
        showPack = this.marketerAgencyContent?.showDomesticTourismLink && this.fellowLink !== '';
      } else {
        showPack = !this.isOdysseySite || this.isFCLAgent;
      }
      // const mainSite = this.whiteLabel?.forMainSite && !marketerSite;
      // const agencySite = this.marketerId === '' && !this.whiteLabel?.forMainSite;
      // if (agencySite) showPack = this.marketerAgencyContent?.showPackTourismLink;
      return showPack;
    },
    isSpecialAgent() {
      if (!this.agentContent.agentSiteSpecificProps) return false;
      return this.agentContent.agentSiteSpecificProps.visitorLikeExtAgents.includes(this.agent);
    },
    specificDocketLink() {
      return 'http://fbmnow.com/pnr.php';
    },
  },
  data() {
    return {
      agent: '',
    };
  },
  mounted() {
    if (this.externalBookingInfo) {
      this.agent = this.externalBookingInfo.bookingData.odyAgentCode;
    } else {
      this.agent = this.odyAgentCode;
    }
  },
};
